import React from 'react';

class Accordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
  }

  handleToggle = () => {
    this.setState((prevState) => ({
      active: !prevState.active,
    }));
  };

  render() {
    return (
      <div>
        <button
          className={`accordion-title ${this.state.active ? 'title-active' : 'title-inactive'}`}
          type='button'
          onClick={this.handleToggle}>
          {this.props.title}
        </button>
        <div
          className={`accordion-content ${this.state.active ? 'content-active' : 'content-inactive'}`}
          dangerouslySetInnerHTML={{ __html: this.props.content }}></div>
      </div>
    );
  }
}

export default Accordion;
