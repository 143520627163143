import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import photoCaryl from '../../static/images/team/IMG_0179.jpg';
import photoSarabeth from '../../static/images/team/IMG_0701.jpg';
import photoFreed from '../../static/images/team/IMG_4313.jpg';
import photoBonacci from '../../static/images/team/DrBonacci.jpg';
import Accordion from '../components/Accordion';

const OurDoctors = (props) => {
  return (
    <Layout bodyClass='page-teams'>
      <SEO title='Our Doctors' />
      <div className='intro'>
        <div className='container'>
          <div className='row justify-content-start'>
            <div className='col-12 col-md-12 col-lg-12 order-1'>
              <h1>Our Doctors</h1>
              <div className='pt-6' style={{ borderTop: '1px solid black' }}>
                <div className='row pt-6 pb-4'>
                  <div className='col-12 col-md-6 mb-2'>
                    <div className='team team-summary'>
                      <div className='team-image'>
                        <img alt={`photo of Dr. Sara Beth Bonacci`} className='img-fluid mb-2' src={photoSarabeth} />
                      </div>
                      <div className='team-meta pl-2 m-auto'>
                        <h2 className='team-name'>Dr. Sara Beth Bonacci</h2>
                        <p className='team-description'>D.D.S.</p>
                      </div>
                      <div className='team-content'>
                        <p>
                          <strong>B.A.</strong> - Biology, Ithaca College '99
                        </p>
                        <p>
                          <strong>D.D.S.</strong> - Doctor of Dental Surgery, State University of New York at Buffalo
                          School of Dental Medicine '04
                        </p>
                        <p>
                          <strong>Residency</strong> - General Practice Residency Certificate, St. Josephs Hospital
                          Health Center '05
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-md-6 mb-2'>
                    <div className='team team-summary'>
                      <div className='team-image'>
                        <img alt={`photo of Dr. Jourdan Freed`} className='img-fluid mb-2' src={photoFreed} />
                      </div>
                      <div className='team-meta pl-2 m-auto'>
                        <h2 className='team-name'>Dr. Jourdan H. Freed</h2>
                        <p className='team-description'>D.D.S.</p>
                      </div>
                      <div className='team-content'>
                        <p>
                          <strong>B.Sc</strong> - Physiology, McGill University '83, Montreal Quebec
                        </p>
                        <p>
                          <strong>D.D.S.</strong> - Doctor of Dental Surgery, McGill University '86, Montreal Quebec
                        </p>
                        <p>
                          <strong>Residency</strong> - General Practice Residency Certificate, S.U.N.Y Upstate Medical
                          University '87
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row pb-6'>
                  <div className='col-12 col-md-6 mb-2'>
                    <div className='team team-summary'>
                      <div className='team-image'>
                        <img alt={`photo of Dr. David Caryl`} className='img-fluid mb-2' src={photoCaryl} />
                      </div>
                      <div className='team-meta pl-2 m-auto'>
                        <h2 className='team-name'>Dr. David M. Caryl</h2>
                        <p className='team-description'>D.D.S.</p>
                      </div>
                      <div className='team-content'>
                        <p>
                          <strong>Undergraduate</strong> - Music Education, State University of New York at Potsdam
                        </p>
                        <p>
                          <strong>D.D.S.</strong> - Doctor of Dental Surgery, State University of New York at Buffalo
                          School of Dental Medicine '08
                        </p>
                        <p>
                          <strong>Residency</strong> - General Practice Residency Certificate, St. Josephs Hospital
                          Health Center '09
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-md-6 mb-2'>
                    <div className='team team-summary'>
                      <div className='team-image'>
                        <img alt={`photo of Dr. Joseph Bonacci`} className='img-fluid mb-2' src={photoBonacci} />
                      </div>
                      <div className='team-meta pl-2 m-auto'>
                        <h2 className='team-name'>Dr. Joseph R. Bonacci</h2>
                        <p className='team-description'>Emeritus and Founder</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default OurDoctors;
